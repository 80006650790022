@use '@angular/material' as mat;

mat-form-field {
  @include mat.form-field-density(-2);
}

mat-form-field mat-label {
  font-size: 14px;
  letter-spacing: 1.5px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.87);
}

$font-config: mat.define-typography-config(
        $font-family: 'Roobert',
        $headline-1: mat.define-typography-level(112px, 112px, 300, 'Roobert', -0.0134em),
        $headline-2: mat.define-typography-level(56px, 56px, 400, 'Roobert', -0.0089em),
        $headline-3: mat.define-typography-level(45px, 48px, 400, 'Roobert', 0em),
        $headline-4: mat.define-typography-level(34px, 40px, 400, 'Roobert', 0.0074em),
        $headline-5: mat.define-typography-level(24px, 32px, 400, 'Roobert', 0em),
        $headline-6: mat.define-typography-level(20px, 32px, 500, 'Roobert', 0.0075em),
        $subtitle-1: mat.define-typography-level(16px, 28px, 400, 'Roobert', 0.0094em),
        $subtitle-2: mat.define-typography-level(15px, 24px, 500, 'Roobert', 0.0067em),
        $body-1: mat.define-typography-level(14px, 24px, 500, 'Roobert', 0.0179em),
        $body-2: mat.define-typography-level(14px, 20px, 400, 'Roobert', 0.0179em),
        $button: mat.define-typography-level(14px, 14px, 500, 'Roobert', 0.0893em),
        $caption: mat.define-typography-level(12px, 20px, 400, 'Roobert', 0.0333em),
        $overline: mat.define-typography-level(inherit, 1.125, 400, 'Roobert', 1.5px),
);
@include mat.typography-hierarchy($font-config);
@include mat.core();
$dark-text: #000000;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);
$mat-light-theme-foreground: (
        base: black,
        divider: $dark-dividers,
        dividers: $dark-dividers,
        disabled: $dark-disabled-text,
        disabled-button: rgba($dark-text, 0.26),
        disabled-text: $dark-disabled-text,
        elevation: black,
        secondary-text: $dark-accent-text,
        hint-text: $dark-disabled-text,
        accent-text: $dark-accent-text,
        icon: $dark-accent-text,
        icons: $dark-accent-text,
        text: $dark-primary-text,
        slider-min: $dark-primary-text,
        slider-off: rgba($dark-text, 0.26),
        slider-off-active: $dark-disabled-text,
);
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);
$mat-dark-theme-foreground: (
        base: $light-text,
        divider: $light-dividers,
        dividers: $light-dividers,
        disabled: $light-disabled-text,
        disabled-button: rgba($light-text, 0.3),
        disabled-text: $light-disabled-text,
        elevation: black,
        hint-text: $light-disabled-text,
        secondary-text: $light-accent-text,
        accent-text: $light-accent-text,
        icon: $light-text,
        icons: $light-text,
        text: $light-text,
        slider-min: $light-text,
        slider-off: rgba($light-text, 0.3),
        slider-off-active: rgba($light-text, 0.3),
);
$light-background: #fafafa;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-alpha-4: rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12: rgba(#2c2c2c, 0.12);
$mat-light-theme-background: (
        background: $light-background,
        status-bar: $light-bg-darker-20,
        app-bar: $light-bg-darker-5,
        hover: $dark-bg-alpha-4,
        card: $light-bg-lighter-5,
        dialog: $light-bg-lighter-5,
        disabled-button: $dark-bg-alpha-12,
        raised-button: $light-bg-lighter-5,
        focused-button: $dark-focused,
        selected-button: $light-bg-darker-20,
        selected-disabled-button: $light-bg-darker-30,
        disabled-button-toggle: $light-bg-darker-10,
        unselected-chip: $light-bg-darker-10,
        disabled-list-option: $light-bg-darker-10,
);
$dark-background: #2c2c2c;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#fafafa, 0.04);
$light-bg-alpha-12: rgba(#fafafa, 0.12);
$mat-dark-theme-background: (
        background: $dark-background,
        status-bar: $dark-bg-lighter-20,
        app-bar: $dark-bg-lighter-5,
        hover: $light-bg-alpha-4,
        card: $dark-bg-lighter-5,
        dialog: $dark-bg-lighter-5,
        disabled-button: $light-bg-alpha-12,
        raised-button: $dark-bg-lighter-5,
        focused-button: $light-focused,
        selected-button: $dark-bg-lighter-20,
        selected-disabled-button: $dark-bg-lighter-30,
        disabled-button-toggle: $dark-bg-lighter-10,
        unselected-chip: $dark-bg-lighter-20,
        disabled-list-option: $dark-bg-lighter-10,
);
$mat-primary: (
        50: #e9e4ef,
        100: #c8bcd8,
        200: #a38fbe,
        300: #7e62a4,
        400: #624091,
        500: var(--primary-color),
        600: #3f1a75,
        700: #37166a,
        800: #2f1260,
        900: #200a4d,
        A100: #a583ff,
        A200: #8050ff,
        A400: #5b1dff,
        A700: #4903ff,
        contrast: (
                50: #000000,
                100: #000000,
                200: #000000,
                300: #ffffff,
                400: #ffffff,
                500: #ffffff,
                600: #ffffff,
                700: #ffffff,
                800: #ffffff,
                900: #ffffff,
                A100: #000000,
                A200: #ffffff,
                A400: #ffffff,
                A700: #ffffff,
        ),
);
$theme-primary: mat.define-palette($mat-primary);
body {
  --text-accent-color: #{$light-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$light-primary-text};
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
  --text-primary-color: #{$light-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$light-primary-text};

  // Checkbox
  --mdc-checkbox-selected-checkmark-color: #{$light-primary-text};
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-focus-icon-color: var(--primary-color);
  --mdc-checkbox-selected-hover-icon-color: var(--primary-color);
  --mdc-checkbox-selected-icon-color: var(--primary-color);
  --mdc-checkbox-selected-pressed-icon-color: var(--primary-color);
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: var(--primary-color);
  --mdc-checkbox-selected-hover-state-layer-color: var(--primary-color);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--primary-color);
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
$theme-accent: mat.define-palette($mat-primary);
$mat-warn: (
        50: #fce5e8,
        100: #f7bec5,
        200: #f2929f,
        300: #ed6678,
        400: #e9465b,
        500: var(--warn-color),
        600: #e22138,
        700: #de1b30,
        800: #da1628,
        900: #d30d1b,
        A100: #ffffff,
        A200: #ffcccf,
        A400: #ff999e,
        A700: #ff8086,
        contrast: (
                50: #000000,
                100: #000000,
                200: #000000,
                300: #000000,
                400: #ffffff,
                500: #ffffff,
                600: #ffffff,
                700: #ffffff,
                800: #ffffff,
                900: #ffffff,
                A100: #000000,
                A200: #000000,
                A400: #000000,
                A700: #000000,
        ),
);
$theme-warn: mat.define-palette($mat-warn);
$theme: mat.define-light-theme(
                (
                        color: (
                                primary: $theme-primary,
                                accent: $theme-accent,
                                warn: $theme-warn,
                        ),
                        density: 0,
                )
);
$altTheme: mat.define-dark-theme(
                (
                        color: (
                                primary: $theme-primary,
                                accent: $theme-accent,
                                warn: $theme-warn,
                        ),
                        density: 0,
                )
);
@include mat.all-component-themes($theme);
.theme-alternate {
  @include mat.all-component-colors($altTheme);
}
.mat-mdc-raised-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button {
  padding: 0 1.15em;
  margin: 0 0.65em;
  min-width: 3em;
  line-height: 36.4px;
}
.mat-mdc-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mat-mdc-text-field-wrapper {
  background: white;
}
.group-error {
  display: contents !important;
}
.mat-mdc-form-field-error {
  display: contents !important;
}

.mat-calendar-body-selected {
  background-color: var(--primary-color) !important;
  color: white !important;
}
