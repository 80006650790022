.form-divider {
  max-width: 1024px;
  margin-left: 0;
}

.form-header {
  color: var(--primary-color);
  margin: 30px 0 15px;
  max-width: 1024px;
  border-bottom: solid 1px #dddddd;
  padding: 10px 5px;
  &.with-button {
    display: flex;
    justify-content: space-between;
    max-width: 1024px;
    margin-right: 40px;
    h4 {
      flex-grow: 3;
      padding: 0;
      align-self: center;
    }
    .icon-btn {
      align-self: flex-end;
      flex-grow: 0;
      margin-right: 0 !important;
    }
    @include x-icon-button;
  }
}

.form-subheader {
  color: #777777;
  font-size: 14px;
  padding: 0 0 5px 10px;
}

.expandable-header {
  color: var(--primary-color);
  cursor: pointer;
  span {
    vertical-align: middle;
  }
  .mat-icon {
    vertical-align: middle;
    line-height: 25px;
  }
}

.status-icon {
  display: inline-block;
  vertical-align: middle;
  width: 15px;
  height: 15px;
  border-radius: 10px;
  margin-right: 15px !important;
}

.status-icon-active {
  background-color: #55bd5a;
}
.status-icon-retired {
  background-color: #f54787;
}
.status-icon-hold {
  background-color: #fecc32;
}
.status-icon-ready {
  background-color: #0070ef;
}
.status-icon-ready-enroll {
  background-color: #aa71ff;
}
.status-icon-inactive {
  background-color: #6b6b6b;
}

@media screen and (max-width: 768px) {
}
